<template>
  <div id="student" class="select-main">
    <div class="div-main">
      <loading :loading="loading" :text="loadingText"></loading>
      <el-form id="formSearch" ref="form" label-width="100px" style="border-bottom: 1px solid #f6f6f6; margin-bottom: 5px; margin-top: 5px;">
        <el-form-item label="题目ID" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number"  v-model="questionId" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="学员ID" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number"  v-model="studentId" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="用户名" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="text"  v-model="username" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="答题状态" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="isCompleted" placeholder="请选择">
            <el-option label="已交卷" value="1"></el-option>
            <el-option label="作答中" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属分校" style="width: 300px; margin-bottom:10px;display: inline-block">
          <branch-select ref="branchSelect"></branch-select>
        </el-form-item>
        <el-form-item label="排序" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="orderBy" placeholder="请选择">
            <el-option  label="ID" value="id"></el-option>
            <el-option  label="得分" value="myScore"></el-option>
          </el-select>
        </el-form-item>
        <div style="display:inline-block;margin-left: 60px">
          <el-button type="primary" @click="getData">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </div>
      </el-form>
      <div id="divList">
        <el-table id="tblList" border :data="records" style="width: 100%" v-show="tableShow" header-row-class-name="table-header">
          <el-table-column prop="id" label="ID" align="center" width="100"></el-table-column>
          <el-table-column prop="studentId" label="学员ID" align="center" width="100"></el-table-column>
          <el-table-column prop="questionId" label="题目ID" align="center" width="100"></el-table-column>
          <el-table-column prop="stem" label="题干" align="left"></el-table-column>
          <el-table-column prop="questionTypeName" label="题目类型" align="center" width="100"></el-table-column>
          <el-table-column prop="isCompleted" label="答题状态" align="center" width="100">
            <template slot-scope="scope">
              {{scope.row.isCompleted == true ? "已交卷" : "作答中"}}
            </template>
          </el-table-column>
          <el-table-column prop="myScore" label="得分" align="center" width="100"></el-table-column>
         <el-table-column prop="createdOn" label="创建日期" align="center" width="200"></el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button @click="handleClickView(scope.row)" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page="page" :size="size" @pageChange="handleCurrentChange" @sizeChange="handleSizeChange"></pagination>
      </div>
    </div>
    <edit-dialog ref="editDialog"></edit-dialog>
  </div>
</template>

<script>
import {
  UrlEnum,
  POP_HEIGHT_BIG,
    QuestionTypeEnum
} from "../../../../public/js/common-vue";
import Pagination from "@/components/Pagination";
import EditDialog from "@/components/EditDialog";
import Loading from "@/components/Loading";
import BranchSelect from "../../../components/BranchSelect";
export default {
  name: "LessonRecordList",
  data() {
    return {
      loading: false,
      loadingText:"加载中",
      tableShow:false,
      questionId:'',
      studentId:'',
      isCompleted:'',
      orderBy:'',
      username:'',
      total:0,
      page:1,
      size:20,
      records:[]
    }
  },
  components:{Loading, Pagination,EditDialog,BranchSelect},
  methods:{
    handleClickView(row){
      let url = '';
      if(row.questionType == QuestionTypeEnum.TIAN_KONG){
        url = 'view-tk.html';
      }
      else if(row.questionType == QuestionTypeEnum.CAILIAO){
        url = 'view-cl';
      }
      else if(
          row.questionType == QuestionTypeEnum.PAN_DUAN ||
          row.questionType == QuestionTypeEnum.DAN_XUAN ||
          row.questionType == QuestionTypeEnum.DUO_XUAN ||
          row.questionType == QuestionTypeEnum.BU_DING_XIANG
      ){
        url = 'view-xz.html'
      }
      this.$refs.editDialog.init('view',"70%",POP_HEIGHT_BIG,'查看学员',url+'?id='+row.id);
    },

    handleCurrentChange(page) {
      this.page = page;
      this.getData();
    },
    handleSizeChange(size) {
      this.size = size;
      this.getData();
    },
    makeUrl() {
      let url = UrlEnum.STUDENT_QUESTION_RECORDS;
      url += "?page="+this.page+"&size="+this.size;
      if(this.questionId != ''){
        url += "&questionId="+this.questionId;
      }
      if(this.studentId !=  ''){
        url += "&studentId="+this.studentId;
      }
      if(this.username != ''){
        url += "&username="+this.username;
      }
      if(this.isCompleted != ''){
        url += "&isCompleted="+this.isCompleted;
      }
      if(this.orderBy != ''){
        url += "&orderBy="+this.orderBy;
      }
      let branchId = this.$refs.branchSelect.getBranchId();
      if(branchId != ''){
        url += "&branchId="+ branchId;
      }
      return url;
    },
    getData() {
      this.loading=true;
      const url = this.makeUrl();
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading=false;
        this.total = res.data.total;
        this.records = res.data.list;
        this.tableShow=true;
       })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
        this.tableShow=true;
      })
    },
    reset(){
      this.page=1;
      this.questionId='';
      this.studentId='';
      this.username='';
      this.$refs.branchSelect.setBranchId('');
      this.isCompleted='';
      this.orderBy='';
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.$refs.branchSelect.initData(null);
    const that = this;
    window.addEventListener('message', function (e) {
      if(e.data.key == "refresh"){
        that.$refs.editDialog.hide();
        that.getData();
      }
    }, false)
  }
}
</script>

<style>
.export{
  display:none;
}
.div-main{
  background: white;
  padding:10px 0px;
  height: 100%;
  overflow: auto;
  margin:15px;
}
.select-main{
  height: 100%;
}
.table-header th {
  background-color: #f6f6f6 !important;
}
.el-table td {
  padding: 6px 0 !important;
}
#divList{
  margin:10px;
}
.opt{
  height: 40px;
}
</style>
